import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { gql, useQuery } from '@apollo/client';

export default function MemeIndex() {
  const [imageTags, setImageTags] = useState([]);
  const q = gql`
    query {
      allImageTags {
        _key
        name
        slug
      }
    }
  `;

  const { data, loading, error } = useQuery(q);

  if (!data) {
    return <div>Loading</div>;
  }

  if (imageTags.length < 1) {
    setImageTags(
      data.allImageTags.map((t) => {
        return {
          _key: t._key,
          name: t.name,
          slug: t.slug,
        };
      }),
    );
  }

  return (
    <>
      <Row>
        <Col>
          <h2 style={{ textTransform: 'uppercase', textAlign: 'center', padding: '1.5em', marginBottom: '1.5em' }}>
            Meme List
          </h2>
        </Col>
      </Row>
      <Row>
        {imageTags.map((t) => (
          <Col sm={6} md={3} lg={2}>
            <p>
              <a style={{ color: '#555' }} href={`/memes/tag?t=${t._key}`}>
                {t.name}
              </a>
            </p>
          </Col>
        ))}
      </Row>
    </>
  );
}
